import React from 'react';
import Reveal from '../Reveal';
import Img from 'gatsby-image';

export default function({children,imageMobile,imageDesktop,imageAlt,height,id}) {

	const sources = [
		imageMobile.childImageSharp.fluid,
		{
		...imageDesktop.childImageSharp.fluid,
			media: `(min-width: 768px)`,
		},
	];

	return (
	<div className="container" id={id}>
		<div className="column">
			<Reveal>
				<div className="row p-4">
					<div className="col-12 col-sm-6">
						{children}
					</div>
					<div className="col-12 col-sm-6">
						<Img fluid={sources} alt={imageAlt} style={{
							height: '100%',
							width: '100%',
						}} />
					</div>
				</div>
			</Reveal>
		</div>
	</div>
	);
}
