import React from 'react';
import settings from '../../settings';

export default function ({heading,children}){
	return (
	<div className="row align-items-center" style={{height:'100%'}} >
		<div>
			<h3>{heading}</h3>
			<hr className="my-4" style={{
				backgroundColor: settings.color.bg.light,
				height: '1px',
				marginLeft: '0',
				maxWidth: '130px',
			}}/>
			<p style={{fontWeight:'100'}}>{children}</p>
		</div>
	</div>
	);
}