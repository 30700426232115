import React from 'react';
import settings from '../../settings';
import BackgroundImage from 'gatsby-background-image';

export default function({
		children,
		bgImageSources,
		bgColor,
		styleBg,
		styleBgMask,
		styleText,
		shadowBottom,
		shadowTop,
		isH1,
		id,
	}) {

	const styles	=	{};
	
//	----------
//	Background
//	----------
	const styles_background_contitional	=	{};
	if(shadowBottom){
		styles_background_contitional.boxShadow	=	'black 0px 8px 15px 0px';
//		styles_background_contitional.boxShadow	=	'#ccc 0px 2px 13px 0px';
		styles_background_contitional.zIndex	=	'99';
	}
	if(shadowTop){
		styles_background_contitional.boxShadow	+=	', inset #333 0px 6px 11px -5px';
//		styles_background_contitional.boxShadow	+=	', #fff 0px 6px 3px -5px inset';
	}
// 	if( bgImage ){
// 		styles_background_contitional.backgroundImage		=	"url('"+bgImage+"')";
// 		styles_background_contitional.backgroundPosition	=	'50% 50%';
// 		styles_background_contitional.backgroundSize		=	'cover';
// 	}

	styles.background	=	{
		backgroundColor: bgColor || settings.color.bg.light,
		height:	'175px',
		maxHeight: '175px',
		position: 'relative',
		width: '100%',
		...styles_background_contitional,
		...styleBg,	//	given_styles from parent
	};

//	---------------
//	Background Mask
//	---------------
	styles.backgroundMask	=	{
		backgroundColor: bgColor || settings.color.bg.light,
		opacity: styleBgMask ? styleBgMask.opacity : 0.2,
		position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		...styleBgMask	//	given_styles from parent
	};

//	----
//	Text
//	----
	styles.text	=	{
				color: settings.color.font.dark,
				fontSize: '34px',
				fontWeight: '100',
				height: '100%',
				letterSpacing: '1px',
				lineHeight: '175px',
				margin: 'auto auto auto auto',
				maxWidth: '600px',
				paddingLeft: '5%',
				paddingRight: '5%',
				position: 'relative',
				textAlign: 'center',
				width: '100%',
				textShadow: bgImageSources?'1px 1px 2px white':null,
				...styleText	//	given_styles from parent
	};

	const html_mask		=	styleBgMask ? <div style={styles.backgroundMask}></div> : null;
	const html_title	=	isH1?<h1 style={styles.text}>{children}</h1>:<h2 style={styles.text}>{children}</h2>;

	if( bgImageSources ){
		return (
			<BackgroundImage className="Banner" fluid={bgImageSources} style={styles.background} id={id} >
				{html_mask}
				{html_title}
			</BackgroundImage>
		);
	}else{
		return (
			<div className="Banner" style={styles.background} id={id} >
				{html_mask}
				{html_title}
			</div>
		);
	}

}
