import React from 'react';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';
import settings from '../../settings';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import SectionImageLeft from '../../components/SectionImageLeft';
import SectionImageRight from '../../components/SectionImageRight';
import TextHeadingParagraph from '../../components/TextHeadingParagraph';

//	https://envturfmgmt.com/services/tree-and-shrub-care/
//	https://www.designscapescolorado.com/portfolio/english-perennial-garden
//	https://www.3littlegreenwoods.com/wp-content/uploads/2013/06/pmDSC02794-1024x685.jpg
//	https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/fetch/NexGen/ContentPage/BarrelPlanters-PlanterTypes-visnav.jpg


export default function() {

	const section_height = '400px';

	const {
			imageMobile_baskets_01,		imageDesktop_baskets_01,
			imageMobile_perennials_01,	imageDesktop_perennials_01,
			imageMobile_pots_01,		imageDesktop_pots_01,
			imageMobile_shrubs_01,		imageDesktop_shrubs_01,
			imageMobile_trees_01,		imageDesktop_trees_01,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_baskets_01: file(relativePath: { eq: "pages/planting/istock.baskets.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_baskets_01: file(relativePath: { eq: "pages/planting/istock.baskets.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_perennials_01: file(relativePath: { eq: "pages/planting/istock.perennials.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_perennials_01: file(relativePath: { eq: "pages/planting/istock.perennials.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_pots_01: file(relativePath: { eq: "pages/planting/pots.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_pots_01: file(relativePath: { eq: "pages/planting/pots.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_shrubs_01: file(relativePath: { eq: "pages/planting/istock.shrubs.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_shrubs_01: file(relativePath: { eq: "pages/planting/istock.shrubs.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_trees_01: file(relativePath: { eq: "pages/planting/istock.trees.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:400, cropFocus:NORTHWEST) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_trees_01: file(relativePath: { eq: "pages/planting/istock.trees.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640, maxHeight:400, cropFocus:NORTHWEST) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
  );

	return (
		<Layout>
			<Helmet>
				<title>Planting • Blackstone VT</title>
				<link rel="canonical" href={`${settings.host.url}/planting`} />
				<meta
					name="description"
					content="Planting services in Southern Vermont. We're available to plant trees, shrubs, perennials, and anything needed to make your property beautiful."
				/>
			</Helmet>
			<Banner id="trees" shadowBottom={true}>Planting</Banner>
			<div className="container">
				<SectionImageRight
					imageAlt="Young Maple Tree in Fall / Autumn"
					imageMobile={imageMobile_trees_01}
					imageDesktop={imageDesktop_trees_01}
					height={section_height}
				>
					<TextHeadingParagraph heading="Trees">
						All beautiful in unique ways.
						Get the most of fall foliage with maple, birch clumps, or young connifers.
						We offer a comprehensive selection of young trees that will match your vision for the property.
					</TextHeadingParagraph>
				</SectionImageRight>
				<SectionImageLeft id="shrubs"
					imageAlt="Fresh Shrubs / Shrubbery in Bed of Mulch"
					imageMobile={imageMobile_shrubs_01}
					imageDesktop={imageDesktop_shrubs_01}
					height={section_height}
				>
					<TextHeadingParagraph heading="Shrubs">Professional placement giving your landscape a full but clean look.</TextHeadingParagraph>
				</SectionImageLeft>
				<SectionImageRight id="perennials"
					imageAlt="Perennial Flower Beds aside a Lawn"
					imageMobile={imageMobile_perennials_01}
					imageDesktop={imageDesktop_perennials_01}
					height={section_height}
				>
					<TextHeadingParagraph heading="Perennials">Add depth and beauty to your garden.</TextHeadingParagraph>
				</SectionImageRight>
				<SectionImageLeft id="baskets"
					imageAlt="Flower Baskets on a Fense"
					imageMobile={imageMobile_baskets_01}
					imageDesktop={imageDesktop_baskets_01}
					height={section_height}
				>
					<TextHeadingParagraph heading="Flower Baskets">Add accent and charm to your home or business. Whether containers, hanging baskets or window boxes, let us maintain these features for you.</TextHeadingParagraph>
				</SectionImageLeft>
				<SectionImageRight id="potting"
					imageAlt="Flower Pots on a Deck"
					imageMobile={imageMobile_pots_01}
					imageDesktop={imageDesktop_pots_01}
					height={section_height}
				>
					<TextHeadingParagraph heading="Potting">Instantly add character with the variety of styles and shapes of pots and planters.</TextHeadingParagraph>
				</SectionImageRight>
			</div>
		</Layout>
	);
}